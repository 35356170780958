import * as React from 'react';
import {
    List,
    useListContext,
    TopToolbar,
    FilterButton,
    SelectInput,
    SelectColumnsButton,
    TextField,
    DatagridConfigurable,
    Loading,
    useTranslate,
    useGetIdentity, downloadCSV,
    ExportButton,
    useGetList, AutocompleteArrayInput, ReferenceInput, useLocales, useLocaleState, useStore, Pagination
} from 'react-admin';

import {
    useMediaQuery,
    Theme,
    Card
} from '@mui/material';

import './style.css';

import {ListProps} from "../fields/RowStyle";
import DatePickerFormatable from "../fields/DatePickerFormatable";
import {useEffect, useState} from "react";
import {isEmptyArray, isEmptyObject} from "../fields/util";
import {useTheme} from "@mui/material/styles";
import SearchButton from "./searchButton";
import LongField from "../fields/LongField";
import MoneyField, {FIXED_FORMAT_OPTIONS, NO_FIXED_FORMAT_OPTIONS} from "../fields/MonyField";
import dayjs, {Dayjs} from "dayjs";
import QuickDateFilter from "../fields/QuickDateFilter"
import ReportShow from "./ReportShow";
import ECPM from "../fields/EcpmField";
import EmptyField from "../fields/EmtyField";
import jsonExport from 'jsonexport/dist';
import FixedNumberField from "../fields/FixedNumberField";
import {useListPaginationContext} from "ra-core";
import SearchWithButton from "../fields/SearchWithButton";

const ListActions = () => (
    <TopToolbar>
        <FilterButton/>
        <SelectColumnsButton/>
        <ExportButton/>

    </TopToolbar>
);

const groupByChoices = [
    {
        id: 'website',
        name: 'Website',
    },
    {
        id: 'adUnit',
        name: 'AdUnit',
    },
    {
        id: 'advertiser',
        name: 'Advertiser',
    },
    {
        id: 'date',
        name: 'Date',
    },
    {
        id: 'user',
        name: 'Publisher',
    },
    {
        id: 'month',
        name: 'Month',
    },
    {
        id: 'date_only',
        name: 'Date only',
    },
];


const EntityList = () => {
    const isXSmall = useMediaQuery<Theme>(theme =>
        theme.breakpoints.down('sm')
    );

    // useEffect(() => {
    //     const defaultColumns = localStorage.getItem("RaStore.preferences.report-ad-exchanges/report.datagrid.columns");
    //     if (isEmptyObject(defaultColumns)) {
    //         localStorage.setItem("RaStore.preferences.report-ad-exchanges/report.datagrid.columns", "[\"0\",\"1\",\"2\",\"3\",\"4\",\"5\",\"6\",\"7\",\"8\",\"9\",\"10\",\"11\"]")
    //     }
    // }, [])

    const [localState, setLocalState] = useState({
        tmp_userId_in: [],
        tmp_sourceId_in: [],
        tmp_websiteId_in: [],
        tmp_adUnitId_in: []
    });

    const filterAll = Object.assign({},
        isEmptyArray(localState.tmp_sourceId_in) ? null : {sourceId_in: localState.tmp_sourceId_in},
        isEmptyArray(localState.tmp_websiteId_in) ? null : {websiteId_in: localState.tmp_websiteId_in},
        isEmptyArray(localState.tmp_adUnitId_in) ? null : {adUnitId_in: localState.tmp_adUnitId_in},
        isEmptyArray(localState.tmp_userId_in) ? null : {userId_in: localState.tmp_userId_in}
    );

    const {identity, isLoading: identityLoading} = useGetIdentity();
    const isAdmin = identity?.authorities.includes('ROLE_ADMIN') || identity?.authorities.includes('ROLE_ADMIN_DATA') || identity?.authorities.includes('ROLE_ACCOUNTING');

    const entityFilters = [
        <SelectInput
            alwaysOn
            isRequired
            source="tmp_groupBy"
            choices={groupByChoices}
            variant="outlined"
        />,
        <ReferenceInput
            source="tmp_userId_in"
            reference="users"
            alwaysOn
            filter={isEmptyArray(localState?.tmp_userId_in) ? filterAll : {orIds: localState.tmp_userId_in, ...filterAll}}>
            <AutocompleteArrayInput
                variant={"outlined"}
                sx={{minWidth: "200px"}}
                optionText={(choice?: any) =>
                    choice?.id // the empty choice is { id: '' }
                        ? `${choice.login}`
                        : ''
                }
                filterToQuery={(q) => {
                    return {
                        login_contains: q, orIds: localState.tmp_userId_in,
                        ...filterAll
                    }
                }}
            />
        </ReferenceInput>,
        <ReferenceInput
            alwaysOn={!isXSmall}
            source="tmp_websiteId_in"
            reference="websites"
            filter={isEmptyArray(localState?.tmp_websiteId_in) ? filterAll : {orIds: localState.tmp_websiteId_in, ...filterAll}}>
            <AutocompleteArrayInput
                alwaysOn
                variant={"outlined"}
                sx={{minWidth: "200px"}}
                optionText={(choice?: any) =>
                    choice?.id // the empty choice is { id: '' }
                        ? `${choice.domain}`
                        : ''
                }
                filterToQuery={(q) => {
                    return {
                        domain_contains: q, orIds: localState.tmp_websiteId_in,
                        status_equals: 'activate',
                        ...filterAll
                    }
                }}
            />
        </ReferenceInput>,
        <ReferenceInput
            alwaysOn
            source="tmp_sourceId_in"
            reference="sources"
            filter={isEmptyArray(localState?.tmp_sourceId_in) ? filterAll : {orIds: localState.tmp_sourceId_in, ...filterAll}}>
            <AutocompleteArrayInput
                alwaysOn
                variant={"outlined"}
                sx={{minWidth: "200px"}}
                optionText={(choice?: any) =>
                    choice?.id // the empty choice is { id: '' }
                        ? `${choice.name}`
                        : ''
                }
                filterToQuery={(q) => {
                    return {
                        name_contains: q, orIds: localState.tmp_sourceId_in,
                        ...filterAll
                    }
                }}
            />
        </ReferenceInput>,
        <ReferenceInput
            source="tmp_adUnitId_in"
            reference="ad-units"
            filter={isEmptyArray(localState?.tmp_adUnitId_in) ? filterAll : {orIds: localState.tmp_adUnitId_in, ...filterAll}}>
            <AutocompleteArrayInput
                alwaysOn
                variant={"outlined"}
                sx={{minWidth: "200px"}}
                optionText={(choice?: any) =>
                    choice?.id // the empty choice is { id: '' }
                        ? `${choice.display}`
                        : ''
                }
                filterToQuery={(q) => {
                    return {
                        display_contains: q, orIds: localState.tmp_adUnitId_in,
                        ...filterAll
                    }
                }}
            />
        </ReferenceInput>,
        <EmptyField alwaysOn source={"tmp_empty"} cellClassName={"break"}/>,
        <QuickDateFilter alwaysOn source={"tmp_tmp_1"} label={"resources.report.filters.this_month"}/>,
        <DatePickerFormatable alwaysOn source="tmp_dateReported_greaterThanOrEqual"/>,
        <DatePickerFormatable alwaysOn source="tmp_dateReported_lessThanOrEqual"/>,
        <SearchButton source={"tmp_tmp_2"} alwaysOn/>
    ];
    if (isAdmin) {
        entityFilters.push(<SearchWithButton source="tmp_source_like" resettable/>,)
    }

    const [availableColumns] = useStore<any[]>(`preferences.report-ad-exchanges/report.datagrid.availableColumns`, []);
    const [columns] = useStore<any[]>(`preferences.report-ad-exchanges/report.datagrid.columns`, availableColumns.map(c => c.index));
    const exporter = (posts: any) => {
        const moneyRate = 1; // vi da nhan san he so
        const get = (str: any): any => {
            return localStorage.getItem(str) as any
        }
        const langKey = get("langKey");
        const headers = availableColumns.filter(f => columns.includes(f.index)).map(f => f.source);

        const postsForExport = posts.map((post: any) => {
            const output = {} as any
            headers.forEach((h: any) => {
                output[h] = post[h]
            })

            const formatter = new Intl.NumberFormat(langKey, langKey === "vi" ? NO_FIXED_FORMAT_OPTIONS : FIXED_FORMAT_OPTIONS);
            const revenueColumns = langKey === "en" ? "revenue" : "revenueVnd";
            const estimatedRevenueColumns = langKey === "en" ? "estimatedRevenue" : "estimatedRevenueVnd";
            if (!isEmptyObject(headers.find(h => h === 'ECPM'))) {
                const estimatedRevenue = !isEmptyObject(post?.[revenueColumns]) ? post[revenueColumns] : 0;
                const impressions = !isEmptyObject(post?.["impressions"]) ? post["impressions"] : 0;
                output['ECPM'] = formatter.format(estimatedRevenue * moneyRate * 1000 / impressions);
            }

            if (!isEmptyObject(headers.find(h => h === 'ACPM'))) {
                const estimatedRevenue = !isEmptyObject(post?.[estimatedRevenueColumns]) ? post[estimatedRevenueColumns] : 0;
                const impressions = !isEmptyObject(post?.["impressions"]) ? post["impressions"] : 0;
                output['ECPM'] = formatter.format(estimatedRevenue * moneyRate * 1000 / impressions);
            }

            if (!isEmptyObject(headers.find(h => h === "revenue"))) {
                const revenue = !isEmptyObject(post?.["revenue"]) ? post["revenue"] : 0;
                output['revenue'] = formatter.format(revenue * moneyRate);
            }

            if (!isEmptyObject(headers.find(h => h === "estimatedRevenue"))) {
                const estimatedRevenue = !isEmptyObject(post?.["estimatedRevenue"]) ? post["estimatedRevenue"] : 0;
                output['estimatedRevenue'] = formatter.format(estimatedRevenue * moneyRate);
            }

            if (!isEmptyObject(headers.find(h => h === "revenueVnd"))) {
                const revenue = !isEmptyObject(post?.["revenueVnd"]) ? post["revenueVnd"] : 0;
                output['revenueVnd'] = formatter.format(revenue * moneyRate);
            }

            if (!isEmptyObject(headers.find(h => h === "estimatedRevenueVnd"))) {
                const estimatedRevenue = !isEmptyObject(post?.["estimatedRevenueVnd"]) ? post["estimatedRevenueVnd"] : 0;
                output['estimatedRevenueVnd'] = formatter.format(estimatedRevenue * moneyRate);
            }

            return output;
        });
        jsonExport(postsForExport, {
            headers: headers // order fields in the export
        }, (err, csv) => {
            downloadCSV(csv, 'Zholding'); // download as 'posts.csv` file
        });
    };
    const PostPagination = () => <Pagination rowsPerPageOptions={[50, 100, 200]} />;

    return (

        <List
            pagination={<PostPagination />}
            exporter={exporter}
            filterDefaultValues={{groupBy: 'website'}}
            filters={entityFilters}
            actions={<ListActions/>}
            title="Reports"

            className={"report-list"}
        >
            <TabbedDataGrid isXSmall={isXSmall} setFiltered={setLocalState} isAdmin={isAdmin} identity={identity}/>
        </List>
    )
};

const getRowStyle = (total: number, theme: any) => (record: any) => {
    if (record.id === total) {
        return {
            backgroundColor: theme.palette.info.main
        }

    }
    return {}
}

export const getRate = (currency: any, thisDate: number = dayjs().unix()) => {
    if (isEmptyObject(currency.rate)) {
        return currency?.rateDefault;
    }
    if (isEmptyObject(currency.rate.find)) {
        return 1;
    }
    const rate = currency.rate.find((r: any) => {
        return dayjs(r?.fromDate, "YYYY-MM-DD").unix() <= thisDate && dayjs(r?.toDate, "YYYY-MM-DD").unix() > thisDate;
    });

    if (!isEmptyObject(rate) && !isEmptyObject(rate.rate)) {
        return rate.rate;
    }

    return currency?.rateDefault;
}

const TabbedDataGrid = (props: ListProps) => {
    const {isXSmall, setFiltered, isAdmin, identity} = props;
    const translate = useTranslate();
    const {filterValues, data, total, isLoading} = useListContext();
    const theme = useTheme();
    const [locale, _] = useLocaleState();
    const showMatchedRequest = isAdmin || identity?.authorities.includes('ROLE_VIEW_MATCHED_REQUEST');
    const showCoverage = isAdmin || identity?.authorities.includes('ROLE_VIEW_COVERAGE');
    const showFillrate = isAdmin || identity?.authorities.includes('ROLE_VIEW_FILLRATE');
    const langKey = isAdmin ? locale : identity?.langKey;
    const {data: localeData} = useGetList("configs", {
        pagination: {page: 1, perPage: 10},
        sort: {field: 'id', order: "DESC"},
        filter: {name_equals: langKey}
    });


    const {page, perPage, setPerPage} = useListPaginationContext();
    useEffect(() => {
        if (window.localStorage.length > 200) {
            window.localStorage.clear();
        }
        setPerPage(50)
    }, [])

    useEffect(() => {
        setFiltered(filterValues)
    }, [filterValues]);

    if (isLoading || localeData?.length !== 1) {
        return <Loading/>
    }

    if (isEmptyArray(data)) {
        return <p>No data</p>
    }

    localStorage.setItem("langKey", langKey);
    const getGroupName = () => {
        return groupByChoices.find(choice => choice.id === filterValues.groupBy)?.name
    }

    const totalData = data?.reduce((ac, value) => {
        Object.keys(value).forEach(key => {
            if (ac[key] === '' || ac[key] === 'Infinity' || value[key] === 'Infinity') {
                return;
            }
            ac[key] = (isEmptyObject(ac[key]) ? 0 : ac[key]) + (isEmptyObject(value[key]) ? 0 : value[key])
        })
        return ac
    }, {group: '', user: ''});
    if (!isEmptyObject(totalData)) {
        totalData.id = -1;
        totalData.group = translate("resources.report.total")
    }
    const rateLargeThan0 = data.filter(d => d.rate > 0).length;
    const coverRateLargeThan0 = data.filter(d => d.coverage > 0 && d.coverage !== "Infinity").length;
    const totalDataPoint = {
        ...totalData,
        rate: totalData.rate / rateLargeThan0,
        coverage: totalData.coverage / coverRateLargeThan0
    }

    const dataToShow = data.slice(perPage * (page - 1), page * perPage);

    return (
        <Card sx={{flex: 1}}>
            {isXSmall ? (
                <DatagridConfigurable className={"report-table report-table-small"}
                                      data={[totalDataPoint, ...dataToShow].map((d: any) => {
                                          return {...d, id: d.id + 1}
                                      })}
                                      total={total + 1}
                                      expand={<ReportShow moneyRate={1} lang={langKey}
                                                          showMatchedRequest={showMatchedRequest}/>}
                                      rowStyle={getRowStyle(0, theme)}
                                      isLoading={isLoading}>
                    <TextField source="group" label={getGroupName()} sortable={false}/>
                    <LongField source="impressions" rate={1} lang={langKey}/>

                    {langKey === "en" && <MoneyField source="revenue" rate={1} lang={langKey}/>}
                    {langKey === "vi" && <MoneyField source="revenueVnd" rate={1} lang={langKey}/>}
                </DatagridConfigurable>
            ) : (
                <>
                    <p style={{float: "right"}}>{translate("resources.report.note")}</p>
                    <DatagridConfigurable className={"report-table report-table-lg"}
                                          data={[totalDataPoint, ...dataToShow].map((d: any) => {
                                              return {...d, id: d.id + 1}
                                          })}
                                          total={total + 1}
                                          rowStyle={getRowStyle(0, theme)}
                                          isLoading={isLoading}>
                        <TextField source="id"/>
                        <TextField source="group" label={getGroupName()} sortable={false}/>
                        <TextField source="user" sortable={false}/>
                        {showMatchedRequest && <LongField source="matchedRequests" lang={langKey}/>}
                        <LongField source="adRequests" lang={langKey}/>
                        {showCoverage && <FixedNumberField source="coverage" lang={langKey}/>}
                        <LongField source="impressions" rate={1} lang={langKey}/>

                        {showFillrate && <FixedNumberField source="rate" lang={langKey}/>}
                        {/*<MoneyField source="matchedECPM" rate={moneyRate} lang={langKey} sortable={false}/>*/}

                        {langKey === "en" && <ECPM source="ECPM" rate={1} lang={langKey} revCol={"revenue"}/>}
                        {langKey === "vi" && <ECPM source="ECPM" rate={1} lang={langKey} revCol={"revenueVnd"}/>}


                        {isAdmin && langKey === "en" &&
                        <ECPM source="ACPM" rate={1} lang={langKey} revCol={"estimatedRevenue"}/>}
                        {isAdmin && langKey === "vi" &&
                        <ECPM source="ACPM" rate={1} lang={langKey} revCol={"estimatedRevenueVnd"}/>}

                        <LongField source="clicks" lang={langKey}/>

                        {isAdmin && langKey === "en" &&
                        <MoneyField source="estimatedRevenue" rate={1} lang={langKey}
                        />}
                        {isAdmin && langKey === "vi" &&
                        <MoneyField source="estimatedRevenueVnd" rate={1} lang={langKey}
                        />}


                        {langKey === "en" && <MoneyField source="revenue" rate={1} lang={langKey}/>}
                        {langKey === "vi" && <MoneyField source="revenueVnd" rate={1} lang={langKey}/>}
                    </DatagridConfigurable>
                    <div className={"report-bottom"}/>
                </>
            )}
        </Card>
    );
}

export default EntityList;
