import * as React from 'react';
import {
    List,
    Count,
    DatagridConfigurable,
    TextField,
    useListContext,
    TopToolbar,
    FilterButton,
    Loading, SelectArrayInput, useTranslate, ReferenceInput, AutocompleteArrayInput,
} from 'react-admin';

import {useMediaQuery, Divider, Tabs, Tab, Theme} from '@mui/material';

import {Fragment, useCallback, useEffect, useState} from 'react';
import CustomDateField from "../fields/CustomDateField";
import SearchWhenEnter from "../fields/SearchWithButton";
import EntityMobileList from "./MobileListItem";
import {ListProps} from "../fields/RowStyle";
import LongTextField from "../fields/LongTextField";
import DatePickerFormatable from "../fields/DatePickerFormatable";
import SyncAction from "./Sync";
import {isEmptyArray} from "../fields/util";


export const AdvertiserTypeChoices = [
    {
        id: 'Google Adx',
        name: 'Google Adx',
    },
    {
        id: 'Google Sheet',
        name: 'Google Sheet',
    },
    {
        id: 'Source adsense',
        name: 'Source adsense',
    },
    {
        id: 'Source admob',
        name: 'Source admob',
    },
    {
        id: 'Source shopee',
        name: 'Source shopee'
    },
    {
        id: '',
        name: 'Empty',
    },
]

const EntityList = () => {
    const isXSmall = useMediaQuery<Theme>(theme =>
        theme.breakpoints.down('sm')
    );
    const translate = useTranslate();
    const [localState, setLocalState] = useState({userId_in: []});

    const websiteFilter = [
        <SearchWhenEnter source="name_contains" alwaysOn/>,
        <ReferenceInput source="userId_in" reference="users" alwaysOn={!isXSmall}
                        filter={isEmptyArray(localState?.userId_in) ? {} : {orIds: localState.userId_in}}>
            <AutocompleteArrayInput variant={"outlined"}
                                    sx={{minWidth: "200px"}}
                                    optionText={(choice?: any) =>
                                        choice?.id // the empty choice is { id: '' }
                                            ? `${choice.login}`
                                            : ''
                                    }
                                    filterToQuery={(q) => {
                                        return {login_contains: q, orIds: localState.userId_in}
                                    }}
                                    name={"userId_in"}
            />
        </ReferenceInput>,
        <SelectArrayInput source="type_in" variant="outlined" alwaysOn={!isXSmall}
                          choices={AdvertiserTypeChoices}
        />,
        <SearchWhenEnter source="idx_contains"/>,
        <SearchWhenEnter source="lastUpdateLog_contains"/>,
        <DatePickerFormatable source="lastUpdateData_greaterThanOrEqual"/>,
        <DatePickerFormatable source="lastUpdateData_lessThan"/>,
    ];

    const ListActions = () => (
        <TopToolbar>
            <SyncAction/>
            <FilterButton/>
        </TopToolbar>
    );
    return (

        <List
            filterDefaultValues={{status_equals: 'activate'}}
            sort={{field: 'id', order: 'DESC'}}
            perPage={25}
            filters={websiteFilter}
            actions={<ListActions/>}
            title={translate('resources.advertisers.name')}
        >
            <TabbedDataGrid isXSmall={isXSmall} setFiltered={setLocalState}/>
        </List>
    )
};

const tabs = [
    {id: 'activate', name: 'Activate'},
    {id: 'deactivate', name: 'Deactivate'},
    {id: 'deactivate_auto', name: 'Deactivate BY System'},
];

const TabbedDataGrid = (props: ListProps) => {
        const {isXSmall, setFiltered} = props;
        const listContext = useListContext();
        const {filterValues, setFilters, displayedFilters} = listContext;

        const handleChange = useCallback(
            (event: React.ChangeEvent<{}>, value: any) => {
                setFilters &&
                setFilters(
                    {...filterValues, status_equals: value},
                    displayedFilters,
                    false // no debounce, we want the filter to fire immediately
                );
            },
            [displayedFilters, filterValues, setFilters]
        );


        useEffect(() => {
            setFiltered(filterValues)
        }, [filterValues]);

        return (
            <Fragment>
                <Tabs
                    variant="fullWidth"
                    centered
                    value={filterValues.status_equals}
                    indicatorColor="primary"
                    onChange={handleChange}
                >
                    {tabs.map(choice => (
                        <Tab
                            key={choice.id}
                            label={
                                <span>
                                {choice.name} (
                                <Count
                                    filter={{
                                        ...filterValues,
                                        'status_equals': choice.id,
                                    }}
                                    sx={{lineHeight: 'inherit'}}
                                />
                                )
                            </span>
                            }
                            value={choice.id}
                        />
                    ))}
                </Tabs>
                <Divider/>
                {isXSmall ? (
                    <EntityMobileList/>
                ) : (
                    <>
                        {['activate', 'deactivate', 'deactivate_auto'].includes(filterValues.status_equals) && (
                            <DatagridConfigurable rowClick="edit" bulkActionButtons={false}>
                                <TextField source="name"/>
                                <TextField source="type"/>
                                <LongTextField source="idx"/>
                                <CustomDateField source="lastUpdateData"
                                                 format="DD/MM/YYYY"/>
                                <TextField source="lastUpdateLog"/>
                            </DatagridConfigurable>
                        )}
                    </>
                )}
            </Fragment>
        );
    }
;

export default EntityList;
